import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        webinfo: [],
        save_category_wise_product: [],
        carts: [],
    },
    mutations: {
        setWb() {
            this.getSlider()
        },

        add_to_cart: (state, {order, quantity}) => {
            let orderInCart = state.carts.find(item => {
                console.log('mu', item, order.item)
                return item.order.Id == order.Id
            })
            if (orderInCart) {
                orderInCart.quantity += quantity;
                return;
            }
            state.carts.push({order, quantity});
            localStorage.setItem('carts', JSON.stringify(state.carts))
        },

        remove_order_from_cart:(state, order ) => {
            state.carts = state.carts.filter(item => {
                console.log(item,order)
                return item.order.Id !== order.Id
            })
        },

        saveCategoryWiseProduct: (state, connections) => {

            // {state.save_category_wise_product = connections;}
            if (localStorage.getItem('pro')) {
                let beforedata = JSON.parse(localStorage.getItem('pro'));
                beforedata.map(item => {
                    if (item == connections) {
                        console.log('yes mil')

                    }
                })
            }
            state.save_category_wise_product.push(connections);
            localStorage.setItem('pro', JSON.stringify(state.save_category_wise_product));
        }
    },
    getters: {
        getWebinfo(state) {
            return state.webinfo;
        },
        cartTotalPrice(state) {
            let total = 0;
            state.carts.forEach(item => {
                total += item.order.OutPrice * item.quantity;
            })

            return total;
        }
    },
    actions: {
        getSlider() {
            axios.get(`https://api.digitalrestora.com/api/Settings/Get/5/webinfo`, {
                headers: {
                    "AuthorizationKey": 'xyz',
                    "DeviceType": 'web'
                }
            })
                .then(res => {
                    this.state.webinfo = JSON.parse(res.data.Data[0].KeyValue);
                })
        },

        addToCart({commit}, {order, quantity}) {
            console.log(order)
            commit('add_to_cart', {order, quantity})

        },
        removeOrderFromCart({commit}, order) {
            commit('remove_order_from_cart', order);
        },
    },
})
