<template>
  <div>
    <div class="banner1">
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner"
}
</script>

<style scoped>
.banner {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}
.banner1 {
  background-image: url(../../assets/images/bg-home1.jpeg);
}
</style>