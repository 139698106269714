import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import './components/index';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import './assets/css/all.min.css';
import './assets/style.css';
import titleMixin from './mixins/titleMixin'
import axios from 'axios';
import VueAxios from 'vue-axios';
import MarqueeText from 'vue-marquee-text-component';
const cors=require("cors");

Vue.mixin(titleMixin)
Vue.use(VueAxios, axios)

Vue.component('marquee-text', MarqueeText)

// import   'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js'

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


// const corsOptions ={
//   origin:'*',
//   credentials:true,            //access-control-allow-credentials:true
//   optionSuccessStatus:200,
// }

Vue.use(cors);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
