<template>
  <section>
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <nav class="nav py-3 margin-top-80">-->
<!--            <a class="nav-link" aria-current="page" href="#">Site map</a>-->
<!--            <a class="nav-link" href="#">Privacy & Policy</a>-->
<!--            <a class="nav-link" href="#">Trams & Condition</a>-->
<!--          </nav>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <back-to-top/>-->
    <!--  <cookie-modal/>-->
<div class=" margin-top-80"></div>
    <div class="mobile-footer-menu">
      <ul>
        <li class="text-center">
          <router-link to="/" class="text-center icon">
            <i class="fas fa-home text-center"></i>
            <span class="d-block">Home</span>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/" class="text-center icon">
            <i class="fas fa-hand-holding-usd"></i>
            <span class="d-block">Live rate</span>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/contact" class="text-center icon">
            <i class="fas fa-headset"></i>
            <span class="d-block">Contact</span>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterMenu"
}
</script>

<style scoped>
.mobile-footer-menu {
  background-color: #1a1706;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  z-index: 9999999;
}

.mobile-footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.mobile-footer-menu ul li {
  list-style: none;
}

.mobile-footer-menu ul li a {
  text-decoration: none;
  color: white;
  /*background-color: #dddddd;*/
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0px;
}
@media screen and (min-width: 600px) {
  .mobile-footer-menu {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .margin-top-80 {
    margin-bottom: 80px;
  }
}
.icon i, span {
  background: -webkit-linear-gradient(#cb9535, #5a4f17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>