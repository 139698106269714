<template>
  <section class="download-section app-download-image">
    <div class="container">
      <h1 class="display-4 text-white pt-5">Download app</h1>
      <div class="row">
        <div class="col-md-6 mt-5">
<!--          <img src="../../assets/images/about/banner-400.png" alt="" class="img-fluid">-->
        </div>
        <div class="col-md-6 mt-5 d-flex align-items-end justify-content-end">

          <div class="download-icon">
            <a href="https://play.google.com/store/apps/details?id=app.ekhidma.mpfgoldllc">
            <img src="../../assets/images/playStore.png" class="img-fluid" alt="">
            </a>
            <a href="https://apps.apple.com/us/app/dc-jewellery/id1600413042">
            <img src="../../assets/images/appStore.png" alt="" class="img-fluid">
            </a>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DownloadApp",
  data(){
    return {
      image: '../../assets/images/banner-back.png',
    }
  }
}
</script>

<style scoped>
.download-section {
  height: 660px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /*padding: 60px 0px;*/
}

.download-icon img {
  margin-right: 40px;
  margin-bottom: 10px;

}
</style>