<template>
  <footer id="contact" class="section footer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="row mt-5">

            <div class="col-md-3 text-center">
              <div class="icon">
                <i class="fas fa-envelope"></i>
              </div>
              <h5><a href="mailto:mpfgoldtradingllc@gmail.com" class="anchor">mpfgoldtradingllc@gmail.com</a></h5>
              <p class="text-muted">Our Mailbox</p>
            </div>
            <div class="col-md-6 address-content text-center">
              <div class="icon text-center">
                <i class="fas fa-globe-americas"></i>
              </div>
              <p class="mb-0">
                Waqf Al Belous Mosque Building,
              </p>
              <p class="mb-0">
                Shop No. 1, Office No. M-1
              </p>
<!--              <p class="mb-0">-->
<!--                Near at Shaker Cafeteria (Chittagong Travel & Tourism LLC),-->
<!--              </p>-->
<!--              <p class="mb-0">-->
<!--                Opposite of Al Khaima Hotel & Abu Ahmed Restaurant &-->
<!--                (Beside of New Mosque)-->
<!--              </p>-->

              <p class="mb-0">
                Al Dhagaya , Deira, Dubai, UAE
              </p>

              <p class="text-muted">Address</p>
            </div>
            <div class="col-md-3 text-center">
              <div class="icon">
                <i class="fas fa-phone-volume"></i>
              </div>
              <h5><a href="tel:+971 4 354 4487" class="anchor">Shop no: +971 4 354 4487</a></h5>
              <h5><a href="tel:+971 4 266 0171" class="anchor">HO: +971 4 266 0171</a></h5>
              <h5><a href="https://api.whatsapp.com/send?phone=+971 56555 6232" class="anchor"><span><i class="fab fa-whatsapp"></i></span>    +971 56555 6232</a></h5>
              <p class="text-muted">Our Phone</p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 text-center">
              <p class="mb-0">Copyright © 2021 MPF Gold trading LLC. All Rights Reserved.</p>
              <p class="text-sm-center"> Design And Developed By <a href="https://ekhidma.com/" target="_blank" class="brand">Ekhidma</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>

export default {

  name: "Footer",

}
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon i {
  font-size: 40px;
  margin: 20px 0px;
  background: -webkit-linear-gradient(#cb9535, #5a4f17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-social-icon li {
  padding: 0px 20px;
}

.footer-social-icon li a {
  background: -webkit-linear-gradient(#cb9535, #5a4f17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer {
  /*height: 500px;*/
  padding-bottom: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #fff;
}
.brand {
  color: #4BBDEF;
  text-decoration: none;
}
.anchor {
  text-decoration: none;
  color: white;
}
</style>