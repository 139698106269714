<template>
  <div>
    <div class="heading text-center">
      <h1 class="display-4">Our customers feedback</h1>
      <h5 class="text-muted">Best food for you and family</h5>
      <img src="../../assets/images/divider.png" alt="" class="img-fluid divider">
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6">
        <carousel :items="1" :loop="true" :nav="false" :autoplay="true" :dots="true"
                  :responsive="{0:{items:1,nav:false},600:{items:1,nav:false}}">
          <div class="qoote">
            <div class="icon-quote">
              <i class="fas fa-quote-left"></i>
            </div>

            <h5>Sed ut perspiciatis unde omnisiste natus error voluptate santium dolorem laudantium, totam rem aperiam
              eaque
              desa quae abillo inventore veritatis et quasi architecto beatae vitae</h5>
            <p class="text-muted mt-3">Mr. Iqbal ahmed tuhin</p>
          </div>
          <div class="qoote">
            <div class="icon-quote">
              <i class="fas fa-quote-left"></i>
            </div>

            <h5>Sed ut perspiciatis unde omnisiste natus error voluptate santium dolorem laudantium, totam rem aperiam
              eaque
              desa quae abillo inventore veritatis et quasi architecto beatae vitae</h5>
            <p class="text-muted mt-3">Mr. Iqbal ahmed tuhin</p>
          </div>
          <div class="qoote">
            <div class="icon-quote">
              <i class="fas fa-quote-left"></i>
            </div>

            <h5>Sed ut perspiciatis unde omnisiste natus error voluptate santium dolorem laudantium, totam rem aperiam
              eaque
              desa quae abillo inventore veritatis et quasi architecto beatae vitae</h5>
            <p class="text-muted mt-3">Mr. Iqbal ahmed tuhin</p>
          </div>
          <div class="qoote">
            <div class="icon-quote">
              <i class="fas fa-quote-left"></i>
            </div>

            <h5>Sed ut perspiciatis unde omnisiste natus error voluptate santium dolorem laudantium, totam rem aperiam
              eaque
              desa quae abillo inventore veritatis et quasi architecto beatae vitae</h5>
            <p class="text-muted mt-3">Mr. Iqbal ahmed tuhin</p>
          </div>

        </carousel>
      </div>
    </div>


  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  name: "FeedBack",
  components: {carousel},
}
</script>

<style scoped>

.icon-quote i {
  margin: 0;
  color: #c19977;
  font-size: 90px;
  line-height: 1;
}
.icon-quote {

  left: 6px;

  position: relative;
  bottom: -42px;
  opacity: 0.3;
}
.qoote h5{
  line-height: 35px;
  font-weight: 300;
}

.qoote {
  padding: 30px 0px;
}
</style>