<template>
  <div>
    <page-title title="Bank details" description=""/>

    <div class="container my-5">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h3>Bank Details</h3>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "BankDetailsMobile"
}
</script>

<style scoped>

</style>