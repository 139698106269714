<template>
  <div class="about">
    <page-title title="Abouts us" description=""/>
    <AboutUs />
  </div>
</template>
<script>
import AboutUs from "../components/sections/AboutUs";

export default {
  title: 'About us',
  components: {AboutUs},
}
</script>