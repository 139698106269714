<template>
  <div id="app" v-cloak>
    <header-component v-if="$route.name != 'mobile'"/>
    <router-view :key="$route.fullPath"></router-view>
    <footer-component v-if="$route.name != 'mobile'"/>
    <footer-menu v-if="$route.name != 'mobile'"/>
  </div>
</template>

<script>


export default {

}
</script>
