<template>
  <div class="page-banner bg-light-golden">
    <div class="container">
      <h3 class="display-4">{{ title }}</h3>
      <p class="mb-0  text-white">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {},
    description: {}
  }
}
</script>

<style scoped>
.page-banner {
  /*background-color: ;*/
  padding: 50px 70px;
  color: white;
}
</style>