<template>
  <div class="container py-5">
    <h3 class="display-4">Contact form</h3>
    <div class="row">
      <div class="col-md-8">

        <b-alert
            :show="dismissCountDown"
            dismissible
            variant="warning"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
        >
          <p>{{ message }}</p>
          <b-progress
              variant="warning"
              :max="dismissSecs"
              :value="dismissCountDown"
              height="4px"
          ></b-progress>
        </b-alert>

        <div class="form" v-if="loading">

          <form @submit="sendEmail">
            <div class="mb-3">
              <label for="fullname">Fullname <span class="text-danger"> * </span></label>
              <input type="text" class="form-control" name="fullname" v-model="form.fullname" id="fullname" required>
            </div>
            <div class="mb-3">
              <label for="email">Email <span class="text-danger"> * </span></label>
              <input type="text" class="form-control" name="email" v-model="form.email" id="email" required>
            </div>

            <div class="mb-3">
              <label for="phone">Phone number <span class="text-danger"> * </span></label>
              <input type="text" class="form-control" name="phone" v-model="form.phone" id="phone" required>
            </div>

            <div class="mb-3">
              <label for="phone">Message <span class="text-danger"> * </span></label>
              <textarea name="message" id="" cols="10" rows="5" v-model="form.message" class="form-control"></textarea>
            </div>

            <div class="mb-3">
              <button class="btn btn-dark btn-lg elementor-button" type="submit">Send</button>
            </div>


          </form>
        </div>
        <div v-else>
          <div class="text-center mt-5">
            <b-spinner type="grow" label="Loading..."></b-spinner>
            <h4 class="text-center">Loading....</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5035.5332628480555!2d55.41541780638744!3d25.190930742593437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f61271e70b5ab%3A0xa69e2f818ab4eb02!2sAl%20Warqaa%20Mosque%20-%20Ibda%20Design!5e0!3m2!1sen!2sbd!4v1658168278616!5m2!1sen!2sbd"
            width="100%" height="545" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        <!--        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d116834.13669278496!2d90.4193257!3d23.7806365!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1635182861407!5m2!1sen!2sbd" width="100%" height="545" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        phone: '',
        message: '',
      },
      loading: true,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
    }
  },
  methods: {
    sendEmail(e) {
      e.preventDefault()
      this.loading = false;
      axios.post(`${process.env.VUE_APP_API_URL}/api/send/email`, this.form)
          .then(res => {

            if (res.data.status_code == 400) {
              this.loading = true;
              this.showDismissibleAlert = true;
              this.dismissCountDown = this.dismissSecs;
              this.message = "Sorry something wrong.Please try again."
            } else {
              this.loading = true;
              this.showDismissibleAlert = true;
              this.message = "Successfully sent.Thank you . We are back soon.";
              this.form = {};
            }
          })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

  }
}
</script>

<style scoped>
.form {
  background: #fff;
  padding: 40px;
}

.form-control {
  border-radius: 0px;
  height: 50px;
}

label {
  margin-bottom: 10px;
}
</style>