<template>
  <div class="px-4">
    <h3 class="display-2 mb-4">{{ title }}</h3>
    <p class="mb-2 h5">{{ title2 }}</p>
    <p class="mb-5">{{ title3 }}</p>
    <img :src="`${image}`" alt="">
    <a href="#" class="btn btn-dark btn-lg elementor-button"> Browse <span class="mx-2"><i
        class="fas fa-long-arrow-alt-right"></i></span></a>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {
    title: {},
    title2: {},
    title3: {},
    image: {},
  }
}
</script>