<template>
  <div>
    <div class="marquee">
      <h1>
        <span> Welcome to  M P F Gold Trading LLC (  এম পি এফ গোল্ড ট্রেডিং এলএলসিতে স্বাগতম )</span> <span
          style="padding-left: 50px"> Call no: <a href="tel:+971 4 354 4487" class="text-info">Shop no: +971 4 354 4487</a></span> ,
        <span style="padding-right: 50px">  <a href="tel:+971 4 266 0171" class="text-info">HO: +971 4 266 0171</a></span>
      </h1>
    </div>

  </div>
</template>
<script>
export default {
  name: "Marquee",
  mounted() {
    window.addEventListener('load', this.Marquee('.marquee', 0.2))
  },
  methods: {
    Marquee(selector, speed) {
      const parentSelector = document.querySelector(selector);
      const clone = parentSelector.innerHTML;
      const firstElement = parentSelector.children[0];
      let i = 0;
      console.log(firstElement);
      parentSelector.insertAdjacentHTML('beforeend', clone);
      parentSelector.insertAdjacentHTML('beforeend', clone);

      setInterval(function () {
        firstElement.style.marginLeft = `-${i}px`;
        if (i > firstElement.clientWidth) {
          i = 0;
        }
        i = i + speed;
      }, 0);
    },


  }
}
</script>

<style scoped>
.marquee {
  overflow: hidden;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  background: #1a1706;
  /*background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);*/
  padding: 5px;
}

.marquee h1 {
  font-size: 14px;
  color: white;
  white-space: nowrap;
  text-transform: uppercase
}
</style>