<template>
  <div>
    <contact-us-component />

    <contact-form-component />
  </div>
</template>

<script>
export default {
  name: "Contact",
  title: 'Contact',
}
</script>

<style scoped>

</style>