<template>
<div>

  <div class="card">
    <img :src="image" class="card-img-top img-fluid" alt="...">
    <div class="card-body">
      <h5 class="card-title font-size-18">{{ title }}</h5>
      <p class="card-text">{{ description }}</p>
<!--      <router-link to="/blog/1" class="btn btn-dark btn-lg elementor-button">view details <span class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></router-link>-->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Blog",
  props: {
    image: {},
    title: {},
    description:{}
  }
}
</script>

<style scoped>
.font-size-18 {
  font-size: 16px;
  font-weight: bold;
}
</style>