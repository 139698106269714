<template>
  <div class="container">
    <div v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="row my-5" v-for="(offer, index) in offer_obj" :key="index">
        <div class="col-md-6">
          <div class="card" style="background-color: #fdf4e1;border: 3px dotted goldenrod">
            <div class="card-body d-flex justify-content-between align-items-center">
              <div>
                <h4 class="card-title">{{ offer.OfferTitle }}</h4>
                <p class="mb-0 text-muted">{{ offer.Description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Offer",
  data() {
    return {
      offer: [],
      offer_obj: [],
      loading: true,
    }
  },
  mounted() {
    this.getOffer();
  },
  methods: {
    getOffer() {
      axios.get(`https://api.digitalrestora.com/api/Settings/Get/3/offer`, {
        headers: {
          "AuthorizationKey": 'xyz',
          "DeviceType": 'web'
        }
      })
          .then(res => {
            this.offer = res.data.Data;
            this.loading = false;
            this.offer.map(item => {
              this.offer_obj.push(JSON.parse(item.KeyValue));
            })

          })
    },
  }
}
</script>

<style scoped>

</style>