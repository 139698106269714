<template>
  <header class="position-relative">
    <div class="header-top">
      <div class="container d-flex justify-content-between align-items-center">
        <router-link to="/">
          <div class="logo d-flex align-items-center">
            <img src="../../assets/logo.png" alt="" class="logo_image mpl_logo pl-3">
          </div>
        </router-link>
        <div class="download-icon">
          <a href="https://play.google.com/store/apps/details?id=app.ekhidma.mpfgoldllc">
            <img src="../../assets/images/playStore.png" class="img-fluid" alt="">
          </a>
          <a href="https://apps.apple.com/us/app/dc-jewellery/id1600413042">
            <img src="../../assets/images/appStore.png" alt="" class="img-fluid">
          </a>
        </div>
      </div>
    </div>
    <div class="">
      <b-navbar toggleable="lg" class="header-menu shadow-sm bg-white pe-2 py-3 " sticky style="position: sticky">
        <div class="container">
          <b-navbar-toggle class="">
            <b-button target="nav-collapse" v-b-toggle.sidebar-backdrop><i class="fas fa-bars"></i></b-button>
          </b-navbar-toggle>

          <b-sidebar id="sidebar-backdrop"
                     backdrop
                     shadow>
            <template #title>
              <router-link to="/" class="bg-golden">
                <div class="logo d-flex align-items-center">

                  <h3 class="text-white mb-0 px-3 sidebar-menu-text">MPF Gold Trade License</h3>

                </div>
              </router-link>
            </template>
            <div class="px-3 py-2">
              <router-link to="/" class="nav-item nav-link sidebar-menu-text">LIVE RATES</router-link>
              <!--              <router-link to="/live-history" class="nav-item nav-link sidebar-menu-text">RATES HISTORY</router-link>-->
              <router-link to="/about" class="nav-item nav-link sidebar-menu-text">ABOUT US</router-link>
              <router-link to="/download" class="nav-item nav-link sidebar-menu-text">DOWNLOAD</router-link>
              <router-link to="/contact" class="nav-item nav-link sidebar-menu-text">CONTACT US</router-link>
              <router-link to="/privacy-policy" class="nav-item nav-link sidebar-menu-text">Policy</router-link>
            </div>
          </b-sidebar>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="me-auto align-items-center">
              <router-link to="/" class="nav-item nav-link">LIVE RATES</router-link>
              <!--              <router-link to="/live-history" class="nav-item nav-link">RATES HISTORY</router-link>-->
              <router-link to="/about" class="nav-item nav-link">ABOUT US</router-link>
              <router-link to="/download" class="nav-item nav-link">DOWNLOAD</router-link>
              <router-link to="/contact" class="nav-item nav-link">CONTACT US</router-link>
              <router-link to="/privacy-policy" class="nav-item nav-link">Policy</router-link>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <marquee-component/>
  </header>
</template>

<script>
export default {
  name: "Header",

}
</script>

<style scoped>
.header-menu {
  background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);
}

.b-sidebar-header {
  /*background: red;*/
  background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);
}

.sidebar-menu-text {
  /*background: red;*/
  background: linear-gradient(
      90deg, #d2b23c, #000000);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.nav-item {
  margin: 0rem .5rem !important;
  font-size: 15px;
  font-weight: bold;
}

.header-menu {
  position: relative;
  z-index: 1;
  /*background: transparent !important;*/
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-brand {
  color: #fff;
}

.welcome-section {
  color: #fff;
  position: relative;
  top: 100px;
}

.logo_image {
  height: 80px;
  transform: scale(1.4);
  /*background-color: #dddddd;*/
}

.header-top {
  background-color: #000000;
  padding: 10px;
}

.download-icon img {
  height: 60px;
  background-color: #1a1706;
  margin: 0px 10px;
}

@media screen and (max-width: 600px) {
  .download-icon {
    display: none;
  }
}

.navbar-toggler {
  position: absolute;
  right: 0;
  top: -74px;
}
.mpl_logo {
  /*width: 175px;*/
}
</style>