<template>
  <div class="container">

    <div class="heading">
      <h1 class="display-4">Gold Trend</h1>
      <img src="../../assets/images/divider.png" alt="" class="img-fluid divider">
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-8 trade-content">
                <p class="mb-0">Buy Above 47701 and book profit around 47796</p>
                <p class="mb-0">Entry Price : 47701</p>
                <p class="mb-0">Stop Loss : 47558</p>
                <p class="mb-0">Target 1 : 47796</p>
                <p class="mb-0">Target 2 : 47916 </p>
                <p class="mb-0">Auto Generated Indicator based on Technical Analysis. Please read the Disclaimer.</p>
              </div>
              <div class="col-md-4">
                <div class="progress">
                  <span class="title timer" data-from="0" data-to="63" data-speed="1800">
                    <span class="percentiage">63.0%</span>
                  </span>
                  <!--                  <div class="overlay"></div>-->
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
                <button class="btn btn-warning font-weight-bold custom-btn" v-if="mobile !='mobile'">BUY</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "GoldTrand",
  props: {
    mobile:{},
  }
}
</script>

<style scoped>

.progress {
  width: 200px;
  height: 200px;
  font-size: 30px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #000;
  text-align: center;
  line-height: 200px;
  margin: 20px
}

.progress::after {
  /*content: "%";*/
}

.progress .title {
  position: relative;
  z-index: 100;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #000000;
}

.progress .left, .progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid #d6a004;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress .left {
  animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right, .progress:nth-of-type(3) .right {
  animation: load2 .5s linear forwards 1s;
}

.progress:last-of-type .right, .progress:first-of-type .right {
  animation: load3 .8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

.percentiage {
  position: absolute;
  left: 60px;
  font-weight: bold;
  transform: scale(1);
}

.custom-btn {
  color: white;
  font-weight: bold;
  padding: 10px 90px;
  margin-left: 22px;
}
.trade-content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
</style>