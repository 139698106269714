<template>
  <section class="aboutus slider-area section-padding golden">
    <!--    <div class="bg-overlay"></div>-->
    <div class="container position-relative">
      <!--      <div class="inner-bg-overlay"></div>-->
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12">
          <div class="image">
            <img src="../../assets/images/about/164.jpg" alt="" class="img-fluid img-thumbnail">
            <br><br>
            <div>
              <div class="card">
                <div class="card-body">
                  <p class="mb-0"><b>M P F Gold Trading LLC is a Dubai based precious metals and precious stones
                    trading
                    company</b>
                    which has established on 27th June, 2019. Since 2019 with a renowned experience within the Precious
                    Metals
                    sector. The Company has an expansive network of operations covering the Middle East
                    <br><br>
                    <b>M P F Gold Trading LLC  </b> began its operation under professional experience,
                    which
                    included
                    Investments, low costs and flexible funding. to choosing an account type that suits you, trading
                    with us
                    means having the freedom to make the most of every market opportunity.
                    <br> <br>
                    The opportunity presented itself to specifically expand the Precious Metals trading sector of <b>M P
                      F
                      Gold
                      Trading LLC  </b> Investments that had been run since June 2019.
                    <br> <br>
                    Since then <b>M P F Gold Trading LLC  </b> has worked with every facet of the gold
                    industry
                    from
                    trading and importing, risk management, financing of mines, assaying, refining, security services
                    and end
                    user vending, ensuring the services provided are efficient and transparent for all involved.</p>
                </div>

              </div>
              <br>

            </div>
            <div class="row align-items-center justify-content-center">
<!--              <div class="col-md-6">-->
<!--                <img src="../../assets/images/mockup.png" alt="" class="img-fluid img-thumbnail">-->
<!--              </div>-->
              <div class="col-md-6">
                <img src="../../assets/about-mpf.jpg" alt="" class="img-fluid img-thumbnail">
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
}
</script>

<style scoped>
.aboutus {
  /*background-image: url(../../assets/images/bg1.jpeg);*/
  /*  background-color: #eeeeee;*/
  /*  background-repeat: no-repeat;*/
  /*  background-position: center;*/
  /*  background-size: contain;*/
}

.section-padding {
  padding: 50px 0px;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-area .bg-overlay {
  background-color: #245192;
  width: 69%;
  left: 0;

}

.slider-area {
  position: relative;
}

.slider-area .container .inner-bg-overlay {
  background-color: #245192;
  position: absolute;
  /* width: 50%; */
  left: 0;
  right: 12%;
  bottom: 0;
  top: 0;
}

.about-us-content {
  position: relative;
  color: #fff;
  padding: 30px 20px;
}

.about-us-content p {
  font-size: 18px;
}
</style>