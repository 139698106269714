import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from "../views/Contact";
import About from "../views/About";
import Gallery from "../views/Gallery";
import Blog from "../views/Blog";
import BlogDetails from "../views/BlogDetails";
import MobileHome from "../views/MobileHome";
import ContactMobile from "../components/sections/ContactMobile";
import BankDetails from "../views/BankDetails";
import AboutUsMobile from "../components/sections/AboutUsMobile";
import BankDetailsMobile from "../views/BankDetailsMobile";
import Download from "../views/Download";
import DownloadMobile from "../views/DownloadMobile";
import History from "../views/History";
import Privency from "../views/Privency";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/mobile',
        name: 'mobile',
        component: MobileHome
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/live-history',
        name: 'History',
        component: History
    },
    {
        path: '/contact/mobile',
        name: 'mobile',
        component: ContactMobile
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/about/mobile',
        name: 'mobile',
        component: AboutUsMobile
    },
    {
        path: '/bank-details',
        name: 'About',
        component: BankDetails,
    },
    {
        path: '/bank-details/mobile',
        name: 'mobile',
        component: BankDetailsMobile,
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/blog/:id',
        name: 'Blog',
        component: BlogDetails
    },
    {
        path: '/download',
        name: 'download',
        component: Download
    },
    {
        path: '/download/mobile',
        name: 'mobile',
        component: DownloadMobile
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: Privency
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,

    routes
})

export default router
