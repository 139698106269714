import Vue from 'vue';

// Layouts
Vue.component('header-component', require('./layouts/Header').default);
Vue.component('footer-component', require('./layouts/Footer').default);
Vue.component('footer-menu', require('./layouts/FooterMenu').default);

// Core
Vue.component('divider', require('./core/Border').default);
Vue.component('page-title', require('./core/PageTitle').default);
Vue.component('cookie-modal', require('./core/CookieModal').default);
Vue.component('back-to-top', require('./core/ScrollBackToTop').default);
Vue.component('loader', require('./core/Loader').default);
Vue.component('download-app', require('./sections/DownloadApp').default);
Vue.component('marquee-component', require('./sections/Marquee').default);
Vue.component('gold-trade-component', require('./sections/GoldTrand').default);
Vue.component('clock', require('./core/RendomNumber').default);
Vue.component('live-time', require('./core/LiveTime').default);




// Components
Vue.component('blog-component', require('./sections/Blog').default);
Vue.component('welcome-component', require('./sections/Welcome').default);
Vue.component('about-us-component', require('./sections/AboutUs').default);
Vue.component('about-us-two-component', require('./sections/AboutUSTwo').default);
Vue.component('contact-us-component', require('./sections/Contact').default);
Vue.component('contact-form-component', require('./sections/ContactForm').default);
Vue.component('banner-component', require('./sections/Banner').default);
Vue.component('feedback-component', require('./sections/FeedBack').default);
Vue.component('team-component', require('./sections/Team').default);
Vue.component('gallery-component', require('./sections/Gallery').default);
Vue.component('offer-component', require('./sections/Offer').default);

//
Vue.component('live-rate', require('./sections/LiveRates').default)
Vue.component('ometer', require('./sections/Odometer').default)