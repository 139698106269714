<template>
  <div>
    <div class="item" :start="10" :end="100" :ounit="ounit">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Odometer",
  props: ["start", "end", "ounit"],
  data() {
    return {
      current: this.start
    };
  },
  computed: {
    test: function () { return "90,100"; },
    size: function (){ return this.start + ',' + this.end; }
  },
  mounted() {
    const that = this;
    var interval = setInterval(function () {
      that.current += 1;
      if (that.current >= that.end) {
        clearInterval(interval);
      }
    }, 20);
  }
}
</script>

<style>


</style>