<template>
  <div>
    <b-modal id="cookieModal" size="lg" hide-footer hide-header>

        <img class="img-fluid" src="https://previews.123rf.com/images/studioworkstock/studioworkstock1601/studioworkstock160100014/49971450-cheeseburger-banner-design-template-fast-food-restaurant-menu-template-vector-illustration-elements-.jpg" alt="">

    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CookieModal",
  mounted() {
    this.$bvModal.show('cookieModal');
  }
}
</script>

<style scoped>
.modal-body {
  padding: 0 !important;
}

</style>