<template>
  <div class="container py-5">
    <h1 class="display-4">Contact Us</h1>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="contact text-center">
          <div>
            <div class="icon ">
              <i class="fas fa-globe-americas"></i>
            </div>
            <div>
              Waqf Al Belous Mosque Building,
            </div>
            <div>
              Shop No. 1, Office No. M-1
            </div>
<!--            <div>-->
<!--              Near at Shaker Cafeteria (Chittagong Travel & Tourism LLC),-->
<!--            </div>-->
<!--            <div>Opposite of Al Khaima Hotel & Abu Ahmed Restaurant &-->

<!--              (Beside of New Mosque)-->
<!--            </div>-->
            <div>
              <p class="mb-0">
                Al Dhagaya , Deira, Dubai, UAE
              </p>
            </div>
            <p class="text-muted">Address</p>
          </div>

          <div class="row justify-content-center align-items-center text-center">
            <div class="col-md-4">
              <div>
                <div class="icon">
                  <i class="fas fa-envelope"></i>
                </div>
                <h5><a href="mailto:mpfgoldtradingllc@gmail.com" class="anchor">mpfgoldtradingllc@gmail.com</a></h5>
                <p class="text-muted">Our Mailbox</p>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <div class="icon">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <h5><a href="tel:+971 4 354 4487" class="anchor">Shop no: +971 4 354 4487</a></h5>
                <h5><a href="tel:+971 4 266 0171" class="anchor">HO: +971 4 266 0171</a></h5>
                <p class="text-muted">phone</p>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <div class="icon">
                  <i class="fab fa-whatsapp"></i>
                </div>
                <h5><a href="https://api.whatsapp.com/send?phone=+971 56555 6232" class="anchor">+971 56555 6232 </a></h5>
                <p class="text-muted">whatsapp</p>
              </div>
            </div>
          </div>

          <div>

          </div>
          <div>



          </div>
<!--          <div class="mt-3">-->
<!--            <ul class="d-flex footer-social-icon mt-5">-->
<!--              <li><a href="mailto:info@dc-jewellery.com"> <i class="fas fa-envelope"></i> </a></li>-->
<!--              <li><a href="tel:+971 55 782 8182"> <i class="fas fa-phone-volume"></i></a></li>-->
<!--&lt;!&ndash;              <li><a href=""> <i class="fab fa-facebook"></i></a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href=""> <i class="fab fa-linkedin"></i></a></li>&ndash;&gt;-->
<!--            </ul>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon i {
  font-size: 40px;
  margin: 20px 0px;
  background: -webkit-linear-gradient(#cb9535, #5a4f17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-social-icon li {
  padding: 0px 20px;
}

.footer-social-icon li a {
  background: -webkit-linear-gradient(#cb9535, #5a4f17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed {
  width: 100%;
  height: 500px;
}
.contact {
  background: #fff;
  padding: 50px;
}
.anchor {
  text-decoration: none;
  color: black;
}
</style>