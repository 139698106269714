<template>
  <div class="container">

    <div class="heading">
      <h1 class="display-4">Products</h1>
      <img src="../../assets/images/divider.png" alt="" class="img-fluid divider">
    </div>
    <div class="row my-5">
      <div class="col-md-3 position-relative">
        <div class="member shadow position-relative">
          <img src="../../assets/buy_gold_bars.png" alt="Notebook" class="img-fluid">
          <div class="content mt-3">
            <h5 class="card-title mb-0">BUY GOLD BARS</h5>
          </div>
        </div>
      </div>
      <div class="col-md-3 position-relative">
        <div class="member shadow position-relative">
          <img src="../../assets/buy_gold_coins.png" alt="Notebook" class="img-fluid">
          <div class="content mt-3">
            <h5 class="card-title mb-0">BUY GOLD COINS</h5>
          </div>
        </div>
      </div>
      <div class="col-md-3 position-relative">
        <div class="member shadowm position-relative">
          <img src="../../assets/buy_silver__bars.png" alt="Notebook" class="img-fluid ">
          <div class="content mt-3">
            <h5 class="card-title mb-0">BUY SILVER BAR</h5>
          </div>
        </div>
      </div>
      <div class="col-md-3 position-relative">
        <div class="member shadow position-relative">
          <img src="../../assets/buy_silver__bars.png" alt="Notebook" class="img-fluid">
          <div class="content mt-3">
            <h5 class="card-title mb-0">BUY SILVER COINS</h5>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Team"
}
</script>

<style scoped>
.member {
  position: relative;
  max-width: 100%; /* Maximum width */
  margin: 0 auto; /* Center it */
}

.member .content {
  position: absolute; /* Position the background text */
  bottom: -63; /* At the bottom. Use top:0 to append it to the top */
  background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);

  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  padding: 20px; /* Some padding */
}
.divider {
  height: 30px;
  margin: 20px 0px;
}
@media screen and (max-width: 600px) {

  .member .content {
    position: relative;
  }
}
</style>