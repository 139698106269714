<template>

  <div class="section">
    <div class="container">
      <div class="download-icon d-flex justify-content-between">
        <div>
          <h1>Available for play store</h1>
          <a href="https://play.google.com/store/apps/details?id=app.ekhidma.mpfgoldllc">
            <img src="../assets/play_store.png" class="img-fluid" alt="">
          </a>
        </div>

        <div>
          <h1>Available for app store</h1>
          <a href="https://apps.apple.com/us/app/dc-jewellery/id1600413042">
            <img src="../assets/app_store.png" alt="" class="img-fluid">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "DownloadMobile"
}
</script>

<style scoped>
.section {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.download-icon {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .section {
    flex-direction: column;
  }
}
</style>