<template>
  <div class="color-lines row no-gutters">
    <div class="col-4 bg-light-golden"></div>
    <div class="col-4 bg-golden"></div>
    <div class="col-4 bg-deep-golden"></div>
  </div>
</template>

<script>
export default {
name: "Border"
}
</script>

<style scoped>
.color-lines {
  height: 10px;
  margin: 0;
  padding: 0;
}

</style>