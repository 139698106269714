<template>
  <div>
    <div class="container">
      <h3>test</h3>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              responsive>

          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "History",
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'ask',
          label: 'Ask',
        },
        {
          key: 'bid',
          label: 'Bid',
        },
        {
          key: 'high_price',
          label: 'High price',
        },
        {
          key: 'low_price',
          label: 'Low price',
        },
        {
          key: 'updated_at',
          label: 'Updated at',
        },
      ]
    }
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/golds/history`)
          .then(res => {
            this.items = res.data;
            console.log(res.data)
          })
    }
  }
}
</script>

<style scoped>

</style>