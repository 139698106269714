<template>
  <section class="section-padding" style="background-color: #000000">
    <div class="">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div>
            <b-tabs content-class="mt-3 text-white">
              <b-tab active>
                <template #title>
                  <div class="font-weight-bold " style="color: darkgoldenrod">
                    Live rate
                  </div>
                </template>
                <div>
                  <!--                  <h3 class="text-white pb-3">Gold rate</h3>-->
                  <div v-if="!loading">
                    <!--  Product table  -->
                    <div class="">
                      <table
                          class="table table-responsive table-bordered text-center">
                        <thead>
                        <tr class="bg-transparent">
                          <th class="p-h product-mobile-width">PRODUCT</th>
                          <th class="p-h">BID</th>
                          <th class="p-h">ASK</th>
                          <th class="p-h">HIGH</th>
                          <th class="p-h">LOW</th>
                        </tr>
                        </thead>

                        <tbody>
                          <tr class="product_goldoz">
                          <td class="product-mobile-width">GOLD OZ</td>
                          <td class="">
                            <div v-if="off_day" class="">
                              <span class="mobile-font-size product-mobile-font"> {{ bid }} </span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'" class="mobile-font-size product-mobile-font">{{
                                  bid
                                }} </span>
                            </div>
                          </td>
                          <td class="">
                            <div v-if="off_day" class="">
                              <span class="mobile-font-size product-mobile-font">{{ ask }}</span>
                            </div>
                            <div class="" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'" class="mobile-font-size product-mobile-font">{{
                                  ask
                                }}</span>
                            </div>
                          </td>
                          <td class="">
                            <span class="mobile-font-size product-mobile-font">{{ high_price }}</span>
                          </td>
                          <td class="">
                            <span class="mobile-font-size product-mobile-font">{{ low_price }}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <!--  Live rateing  -->
                    <div class="table-responsive">
                      <table class="table table-responsive table-bordered">
                        <tbody class="">
                        <tr class="">
                          <td class="font-bold mobile-width">KILO BAR 995</td>
                          <td class="text-center font-size-14 width-15">1KG</td>
                          <td class="text-center font-size-14 width-15">AED</td>
                          <td class="text-center width-30">
                            <div class="transform-scale-mobile" v-if="off_day">
                              <span class="mobile-font-size">{{ bar_9950 }}</span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'"
                                    class="mobile-font-size-14">{{ bar_9950 }}</span>
                            </div>
                          </td>
                        </tr>
                        <tr class="">
                          <td class="font-bold mobile-width"> KILO BAR 9999</td>
                          <td class="text-center font-size-14 width-15">1KG</td>
                          <td class="text-center font-size-14 width-15">AED</td>
                          <td class="text-center width-30">
                            <div v-if="off_day" class="transform-scale-mobile">
                              <span class="mobile-font-size">{{ bar_9999 }}</span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'"
                                    class="mobile-font-size-14">{{ bar_9999 }}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-bold mobile-width">TEN TOLA BAR</td>
                          <td class="text-center font-size-14 width-15">TTB</td>
                          <td class="text-center font-size-14 width-15">AED</td>
                          <td class="text-center width-30">
                            <div class="transform-scale-mobile" v-if="off_day">
                              <span class="mobile-font-size">{{ ten_tola_bar }}</span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'"
                                    class="mobile-font-size-14">{{ ten_tola_bar }}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-bold mobile-width">SILVER KILO BAR</td>
                          <td class="text-center font-size-14 width-15">1KG</td>
                          <td class="text-center font-size-14 width-15">AED</td>
                          <td class="text-center width-30">
                            <div class="transform-scale-mobile" v-if="off_day">
                              <span class="mobile-font-size">{{ silver_kilo_bar }}</span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'"
                                    class="mobile-font-size-14"> {{ silver_kilo_bar }}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-bold mobile-width"> GOLD 9999</td>
                          <td class="text-center font-size-14 width-15">1GM</td>
                          <td class="text-center font-size-14 width-15">AED</td>
                          <td class="text-center width-30">
                            <div class="transform-scale-mobile" v-if="off_day">
                              <span class="mobile-font-size">{{ gold_9999 }}</span>
                            </div>
                            <div class="transform-scale-mobile" v-else>
                              <span :class="change_class == true ? 'success' : 'danger'"
                                    style="margin-left: 13px" class="mobile-font-size-14">{{ gold_9999 }}</span>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <!--                      <h3>Last update : {{ goldInfo.updated_at }}</h3>-->
                    </div>
                  </div>
                  <div v-else>
                    Loading...
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="font-weight-bold " style="color: darkgoldenrod">
                    Products
                  </div>
                </template>
                <div class="row">
                  <div class="col-md-3 mb-3">
                    <div class="card text-dark">
                      <img src="../../assets/images/1.jpeg" class="card-img-top img-fluid height-300" alt="...">
                      <div class="card-body border-top">
                        <h5 class="card-title">Kilo Bar</h5>
                        <h6 class="text-muted">Purity : 999</h6>
                        <p class="card-text">Shape: Rectangle</p>
                        <!--                        <a to="#" class="btn btn-dark btn-lg elementor-button">SHOP <span-->
                        <!--                            class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></a>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="card text-dark">
                      <img src="../../assets/images/2.jpeg" class="card-img-top img-fluid height-300" alt="...">
                      <div class="card-body border-top">
                        <h5 class="card-title">TT Bar</h5>
                        <h6 class="text-muted">Purity : 999</h6>
                        <p class="card-text">Shape: Rectangle</p>
                        <!--                        <a href="#" class="btn btn-dark btn-lg elementor-button">SHOP <span-->
                        <!--                            class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></a>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="card text-dark">
                      <img src="../../assets/images/3.jpeg" class="card-img-top img-fluid height-300" alt="...">
                      <div class="card-body border-top">
                        <h5 class="card-title">Investment Bar</h5>
                        <h6 class="text-muted">Purity : 999</h6>
                        <p class="card-text">Shape: Rectangle</p>
                        <!--                        <a href="#" class="btn btn-dark btn-lg elementor-button">SHOP <span-->
                        <!--                            class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></a>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="card text-dark">
                      <img src="../../assets/images/4.jpeg" class="card-img-top img-fluid height-300" alt="...">
                      <div class="card-body border-top">
                        <h5 class="card-title">Coins</h5>
                        <h6 class="text-muted">Purity : 22K, 24K</h6>
                        <p class="card-text">Shape: Round</p>
                        <!--                        <a href="#" class="btn btn-dark btn-lg elementor-button">SHOP <span-->
                        <!--                            class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></a>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <div class="card text-dark">
                      <img src="../../assets/images/jewellery.jpeg" class="card-img-top img-fluid height-300" alt="...">
                      <div class="card-body border-top">
                        <h5 class="card-title">Jewellery</h5>
                        <h6 class="text-muted text-dark">Purity : 18K, 21K, 22K</h6>
                        <p class="card-text">Shape: Round</p>
                        <!--                        <a href="#" class="btn btn-dark btn-lg elementor-button">SHOP <span-->
                        <!--                            class="mx-2"><i class="fas fa-long-arrow-alt-right"></i></span></a>-->
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import {LiveRateMixin} from "../../mixins/LiveRateMixin";

export default {
  name: "LiveRates",
  mixins: [LiveRateMixin],

}
</script>

<style scoped>
.section-padding {
  padding: 50px 0px;
}

.table {
  color: #e4bf66;
  border: 1px solid #e4bf66;
  width: 100%;
}

tr td {
  /*border:1px solid #bc8b0b;*/
}

tr {
  background-image: linear-gradient(#454545, #000)
}

.bg-golden {
  background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);
}

.height-300 {
  height: 300px;
}

.nav-item {
  background-image: linear-gradient(to bottom, #ab7d09, #9e7409, #916a08, #846108, #785807, #694f08, #5a4509, #4c3c0a, #392f0b, #292309, #1a1706, #060501);

}

.blue {
  background-color: darkslategrey;
  color: white;
}

.red {
  background-color: red;
  color: white;
}

.change {
  animation: myanimation 2s infinite;
  /*margin-right: 30px;*/
  padding: 4px;
  border-radius: 7px;
  color: #ffffff;
  /*font-weight: bold;*/
  /*font-size: 20px !important;*/
  /*font-weight: 500;*/
}

.success {
  background-color: green;
  /*margin-right: 30px;*/
  padding: 4px;
  border-radius: 7px;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px !important;
  /*font-weight: 500;*/
}

.danger {
  background-color: red;
  /*margin-right: 30px;*/
  padding: 4px;
  border-radius: 7px;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px !important;
  /*font-weight: 500;*/
}

@keyframes myanimation {
  0% {
    background-color: red;
  }
  50% {
    background-color: green;
  }
}

@media screen and (max-width: 600px) {
  .table {
    font-size: 14px;
  }
  .product-mobile-font {
    font-size: 15px !important;
    color: #ffffff;
  }

  .mobile-width {
    width: 30% !important;
  }

  .product-mobile-width {
    width: 10% !important;
  }

  /*.transform-scale-mobile {*/
  /*  transform: scale(1.3);*/
  /*  color: white;*/
  /*}*/
  .transform-scale-mobile span {
    font-size: 18px !important;
    color: white;
  }

  .success {
    background-color: green;
    /*margin-right: 30px;*/
    padding: 4px;
    border-radius: 7px;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px !important;
    /*font-weight: 500;*/
  }

  .danger {
    background-color: red;
    /*margin-right: 30px;*/
    padding: 4px;
    border-radius: 7px;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px !important;
    /*font-weight: 500;*/
  }

  .font-size-14 {
    font-size: 12px;
  }

  .mobile-font-size {
    font-size: 18px !important;
    /*transform: scale(1.2);*/
  }

  .font-bold {
    font-weight: 500;
    padding-left: 0px !important;
    width: 40%;
    text-align: center;
    font-size: 13px;
  }

  .mobile-font-size-14 {
    font-size: 14px !important;
    font-weight: 500;
  }
  .p-h {
    font-size: 12px;
    width: 20%;
  }
  .product_goldoz td{
    padding:8px 0px !important;
  }

}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: 500;
  padding-left: 40px;
  width: 40%;
}

.font-size-14 {
  font-size: 14px;
  font-weight: 500;
}

.width-15 {
  width: 15%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

.bg-transparent {
  background: none;
}

.row {
  margin: 0;
  padding: 0;
}

.mobile-font-size {
  font-weight: 500;
  font-size: 20px;
}
</style>