// import axios from "axios";

export const LiveRateMixin = {
    data() {
        return {
            data: [],
            goldInfo: {},
            bid: '',
            ask: '',
            high_price: null,
            low_price: null,
            loading: true,

            kiloBarGold: null,
            tenTolaGold: null,
            silberTolaBar: null,
            gmGold: null,

            interval: null,

            bar_9950: null,
            bar_9999: null,
            ten_tola_bar: null,
            silver_kilo_bar: null,
            gold_9999: null,
            timestamp: "",
            change_class: '',
            off_day: false,
        }
    },

    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
        const today = new Date();
        this.close_time_per_day = this.formatAMPM(today);
        let close_day = today.getUTCDay();
        if ((this.close_time_per_day >= '02:00 am' && this.close_time_per_day <= '03:00 am') || (close_day == 0 || close_day == 6)) {
            this.off_day = true
            this.getGoldInfo();
            this.getGoldInfoAed();
            this.getSilverInfo();
            this.change_class = null;

        } else {
            this.off_day = false
            this.interval = setInterval(() => {
                this.off_day = true
                this.close_time_per_day;
                this.getGoldInfo();
                this.getGoldInfoAed();
                this.getSilverInfo();
            }, 1000)
        }
    },
    mounted() {
        this.currentDateAndTime();
    },

    methods: {
        getGoldInfoAed() {
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", "goldapi-7q9uy0tkwrfdtlo-io");
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://www.goldapi.io/api/XAU/AED", requestOptions)
                .then(response => response.text())
                .then(result => {
                    this.loading = false;
                    let da = JSON.parse(result)
                    this.bar_9950 = (da.price * 31.990).toFixed(2);
                    this.bar_9999 = (da.price * 32.148).toFixed(2);
                    this.ten_tola_bar = (da.price * 3.754).toFixed(2);
                    this.gold_9999 = (da.price * 0.0321507465).toFixed(2);
                })
                .catch(error => console.log('error', error));
        },

        getGoldInfo() {
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", "goldapi-7q9uy0tkwrfdtlo-io");
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://www.goldapi.io/api/XAU/USD", requestOptions)
                .then(response => response.text())
                .then(result => {
                    this.loading = false;
                    let da = JSON.parse(result)
                    this.bid = (da.bid + 0.15).toFixed(2);
                    this.ask = (da.ask - 0.15).toFixed(2);
                    this.high_price = da.high_price - 0.25;
                    this.low_price = da.low_price - 0.25;
                })
                .catch(error => console.log('error', error));
        },

        getSilverInfo() {
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", "goldapi-7q9uy0tkwrfdtlo-io");
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://www.goldapi.io/api/XAG/AED", requestOptions)
                .then(response => response.text())
                .then(result => {
                    this.loading = false;
                    let da = JSON.parse(result)
                    this.silver_kilo_bar = (da.price * 33).toFixed(2);
                })
                .catch(error => console.log('error', error));
        },

        currentDateAndTime() {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            this.timestamp = dateTime;
            this.formatAMPM(today);
        },

        formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        },

        randomIntFromInterval(min, max) { // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
    },
    watch: {

        "bid": function (old, val) {

            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }

        },
        "bar_9950": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },
        "bar_9999": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },
        "ten_tola_bar": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },
        "silver_kilo_bar": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },
        "gold_9999": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },

        "ask": function (old, val) {
            if (old < val) {
                this.change_class = true;
                this.off_day = false;
            } else if (old == val) {
                this.off_day = true
            } else {
                this.change_class = false;
            }
        },
    }
}