<template>
  <div>
    <transition name="fade">
      <div id="pagetop" class="fixed" v-show="scY > 300" @click="toTop">
        <i class="fas fa-chevron-up"></i>
      </div>
    </transition>
  </div>
</template>

<script>
export default {

  name: "ScrollBackToTop",
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
}
</script>

<style scoped>
.fixed {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: var(--bs-orange);
  color: #fff;
  padding: 8px 18px;
  border-radius: 30px;
  font-size: 30px;
  font-weight: 500;
}
</style>