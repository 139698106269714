<template>
  <div>
    <!-- Blog-->
    <page-title title="Our Gallery" description="Health and Food tips...."/>
    <divider />
    <gallery-component />
  </div>
</template>

<script>

export default {
  name: "Gallery",
  title: 'Gallery',

}
</script>

<style scoped>

</style>