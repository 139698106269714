<template>
  <div class="home">
    <!--    ABOUT US-->
    <div class="position-relative">
      <live-rate/>
    </div>
    <section class="team-section">
      <team-component/>
    </section>
    <!-- Blog-->
    <section class="blog-section">
      <div class="container">
        <h1 class="text-white mb-5">GOLD NEWS</h1>
        <div class="row">
          <carousel :items="4" :loop="true" :nav="true" :autoplay="true" :dots="false"
                    :responsive="{0:{items:1,nav:true},600:{items:4,nav:false}}">
            <div class="col-md-3 w-100 px-2" v-for="(blog , index) in blogs" :key="index">
              <blog-component :title="blog.title" :description="blog.description" :image="blog.image"></blog-component>
            </div>
          </carousel>
        </div>
      </div>
    </section>
    <section class="team-section">
      <gold-trade-component mobile="mobile"/>
    </section>
  </div>

</template>

<script>
import carousel from 'vue-owl-carousel'


export default {
  name: 'MobileHome',
  data() {
    return {
      blogs: [
        {
          id: 1,
          title: 'SAT, 04 DEC 2021 16:33:45 GMT',
          description: 'Gold at $10k, silver at $500 due to a decade of shortage, inflationary black swan event',
          image: '/img/buy_gold_coins.c41c6bb8.png',
        },
        {
          id: 2,
          title: 'FRI, 03 DEC 2021 20:45:21 GMT',
          description: 'Gold price wants clarity after Powells hawkish stance - analysts',
          image: '/img/buy_gold_coins.c41c6bb8.png',
        },

        {
          id: 3,
          title: 'FRI, 03 DEC 2021 17:22:15 GMT ',
          description: 'Gold market is ugly with no clear price direction',
          image: '/img/buy_gold_coins.c41c6bb8.png',
        },
        {
          id: 4,
          title: 'WED, 01 DEC 2021 22:46:51 GMT',
          description: 'Gold price at $10k, silver at $500 due to a decade of shortage, says Goehring & Rozencwajg',
          image: '/img/buy_gold_coins.c41c6bb8.png',
        },
      ],
    }
  },
  components: {carousel},
  title: 'Home',


}
</script>
<style>

.left-arrow {
  position: absolute;
  left: -25px;
  top: 38%;
  background: #1768b9;
  padding: 10px;
  border-radius: 5px;
}

.right-arrow {
  position: absolute;
  right: -25px;
  top: 38%;
  background: #1768b9;
  padding: 10px;
  border-radius: 5px;
}

.owl-theme .owl-nav [class*='owl-'] {
  background: none;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: none;
}

.welcome-section {
  /*background: #fff;*/
  padding: 100px 0px;
}

.feedback-section {
  padding: 60px 0px;
  background-color: #fdf4e1;
}

.blog-section {
  background-color: #c8a96a;
  padding: 60px 0px;
}

.trade-section {
  background-color: #8f7024;
  padding: 60px 0px;
}

.offer-section {
  padding: 60px 0px;
  background-color: #fdf4e1;
}

.team-section {
  padding: 60px 0px;
  background: #ede1a4;
}

#odometer-demo {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  text-align: center;
}

.odometer {
  color: black;
  font-size: 16px;
  margin: 0;
}

.item {
  border: 1px solid black;
  margin: 0 auto;
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  transform: rotate(-90deg);
  transform-origin: center;
  transform-box: fill-box;
  width: 500px;
}

.circle-chart__info {
  animation: circle-chart-appear 0.5s forwards;
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.circle-chart__background {
  stroke-width: 1px !important;
}
</style>
