<template>
  <section class="section">
    <div v-if="loading">
      <loader/>
    </div>
    <div class="container py-5">
      <div class="heading text-center">
        <h1 class="display-4">Our beautiful gallery</h1>
        <h5 class="text-muted">Best food for you and family</h5>
        <img src="../../assets/images/divider.png" alt="" class="img-fluid divider">
      </div>
      <div class="row">

        <div class="col-md-3" v-for="(gallery, index) in news_obj" :key="index">
          <div class="card">
            <div class="text-center card-body">
              <img src="../../assets/images/bg1.jpeg" class="img-fluid" alt="...">
              <h4 class="text-center py-2">{{ gallery.Title }}</h4>
              <a href="" class="btn btn-dark">Download</a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>

import axios from "axios";

export default {
  name: "Gallery",

  data() {
    return {
      loading: true,
      news: [],
      news_obj: [],
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios.get(`https://api.digitalrestora.com/api/Settings/Get/3/gallary`, {
        headers: {
          "AuthorizationKey": 'xyz',
          "DeviceType": 'web'
        }
      })
          .then(res => {
            this.news = res.data.Data;
            this.loading = false;
            this.news.map(item => {
              this.news_obj.push(JSON.parse(item.KeyValue));
            })
          })
    },
  }
}
</script>

<style scoped>

</style>