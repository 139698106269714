<template>
  <div>
    <page-title title="Blogs for health tips" description="Health and Food tips...."/>
    <div v-if="loading">
      <loader/>
    </div>
    <div class="container my-5" v-else>
      <div class="row">
        <carousel :items="4" :loop="true" :nav="true" :autoplay="true" :dots="false" :navText="navText"
                  :responsive="{0:{items:1,nav:true},600:{items:4,nav:true}}">
          <div class="col-md-3 w-100 px-2" v-for="(news , index) in news_obj" :key="index">
            <blog-component :title="news.NewsTitle" :description="news.Description" image=""></blog-component>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import axios from "axios";

export default {
  name: "Blog",
  components: {carousel},
  title: 'Blog',
  data() {
    return {
      news: [],
      news_obj: [],
      loading: true,
      navText: [
        "<i class='fas fa-chevron-left left-arrow'></i>", '<i class="fas fa-chevron-right right-arrow"></i>'
      ]
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios.get(`https://api.digitalrestora.com/api/Settings/Get/3/news`, {
        headers: {
          "AuthorizationKey": 'xyz',
          "DeviceType": 'web'
        }
      })
          .then(res => {
            this.news = res.data.Data;
            this.loading = false;
            this.news.map(item => {
              this.news_obj.push(JSON.parse(item.KeyValue));
            })
          })
    },
  }
}
</script>

<style scoped>

</style>