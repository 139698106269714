import { render, staticRenderFns } from "./Privency.vue?vue&type=template&id=d5550868&scoped=true&"
import script from "./Privency.vue?vue&type=script&lang=js&"
export * from "./Privency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5550868",
  null
  
)

export default component.exports