<template>
  <div>
    <page-title title="Blog title"/>

    <section class="blog-details py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <img src="../assets/images/about.jpg" class="card-img-top img-fluid" alt="...">
              <div class="card-body">
                <h5 class="card-title">Blog title</h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut dolorem eligendi
                  iusto suscipit. Accusamus accusantium cupiditate debitis dolorem et excepturi, explicabo fugit id
                  incidunt iste iusto libero magnam maiores modi molestiae necessitatibus non nostrum odio officiis
                  optio provident quaerat quam quibusdam rem repellat saepe sapiente suscipit tenetur veniam veritatis
                  vitae voluptate voluptates voluptatibus? At atque dolore explicabo fugiat magnam quaerat similique,
                  sunt ut voluptatum! Alias, aspernatur assumenda beatae deserunt dignissimos dolores doloribus ea et
                  facilis illum, magnam modi nemo nihil nobis obcaecati officiis perspiciatis placeat quasi quisquam
                  quos saepe suscipit tenetur unde velit veniam veritatis voluptas voluptatibus. Ad, iusto!</p>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut dolorem eligendi
                  iusto suscipit. Accusamus accusantium cupiditate debitis dolorem et excepturi, explicabo fugit id
                  incidunt iste iusto libero magnam maiores modi molestiae necessitatibus non nostrum odio officiis
                  optio provident quaerat quam quibusdam rem repellat saepe sapiente suscipit tenetur veniam veritatis
                  vitae voluptate voluptates voluptatibus? At atque dolore explicabo fugiat magnam quaerat similique,
                  sunt ut voluptatum! Alias, aspernatur assumenda beatae deserunt dignissimos dolores doloribus ea et
                  facilis illum, magnam modi nemo nihil nobis obcaecati officiis perspiciatis placeat quasi quisquam
                  quos saepe suscipit tenetur unde velit veniam veritatis voluptas voluptatibus. Ad, iusto!</p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BlogDetails"
}
</script>

<style scoped>

</style>