<template>
  <div><span class="timer">{{ timer }}</span></div>
</template>

<script>
const INTERVAL = 5000;
export default {
name: "RendomNumber",
  data() {
    return {
      status: 'Stopped',
      timerId: null,
      timer: 0,
      count: 0,
    };
  },
  watch: {
    timer(newTime) {
      if (newTime === -1) {
        this.count += 1;
        this.start();
      }
    },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      clearInterval(this.timerId);
      this.status = 'Running';
      this.timer = this.timer > 0 ? this.timer : INTERVAL / 1000;
      this.timerId = setInterval(() => {
        this.timer -= 1;
      }, 1000);
    },
    pause() {
      clearInterval(this.timerId);
      this.status = 'Paused';
    },
  }
}
</script>

<style scoped>

</style>